import React from "react";
import { useRoomInfoQuery } from "../../../../features/dashboard/dashboardDetails/dashboardDetailsApi";
import "./roomInfo.css";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../../../context/ThemeContext";
import { useContext } from "react";

function RoomInfo() {
  const theme = useContext(ThemeContext);
  const { roomId } = useParams();
  const { data, isLoading, isError } = useRoomInfoQuery(roomId);
  let element;
  if (isLoading) {
    element = <div>Loading</div>;
  }
  if (!isLoading) {
    const roomData = data?.data[0] || {};

    if (roomData) {
      // console.log(roomData);

      const roomInfo = Object.entries(roomData);
      element = roomInfo.map(([key, value]) => {
        if (key != "map_image") {
          if (key == "created_at" || key == "updated_at") {
            const date = new Date(value);
            value = date.toLocaleString();
          }
          return (
            <tr className="tr">
              <td className="td">{key.toUpperCase()}</td>
              <td className="td">{value ? value : "No Info"}</td>
            </tr>
          );
        }
      });
    } else {
      alert("wow");
      element = <div>No Data Available</div>;
    }

    // for(let [key,value]of roomInfo){
    //   console.log(value);
    // }
  }
  return (
    <div className={`${theme}`}>
      <h3 className="theme-text">Chamber Info</h3>
      <div className="theme-text">{element}</div>
      {/* <table className="table">
        <tr className="tr">
          <td className="td">Alfreds Futterkiste</td>
          <td className="td">Maria Anders</td>
        </tr>
      </table> */}
    </div>
  );
}

export default RoomInfo;
