import React, { createContext } from "react";
import { useState, useEffect } from "react";
// import "./styles/main.css";
import { routes } from "../../routes/appRoutes";
import { useRoutes, Link, useNavigate, Navigate } from "react-router-dom";
import { IconContext } from "react-icons";
import Form from "react-bootstrap/Form";
import { ThemeContext } from "../../context/ThemeContext";
import { BiUserCircle } from "react-icons/bi";
import { HiOutlineSun } from "react-icons/hi";
import { BsMoon } from "react-icons/bs";
import { IoMdNotificationsOutline } from "react-icons/io";
import Moment from "react-moment";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import { userLoggedOut } from "../../features/auth/authSlice";
import ReactLoading from "react-loading";
import useAuth from "../../hooks/useAuth";

import "./styles/notification.css";
import DrawerComponent from "./drawer/DrawerComponent";
import Drawer from "./drawer/Drawer";

function Sidebar() {
  const loginCheck = useAuth();
  const c = console.log.bind(console);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const nevigate = useNavigate();
  // c(user);
  const [sideBarToggle, setSideBarToggle] = useState(false);
  const [subMenuToggleStatus, setSubMenuToggleStatus] = useState({});
  const [toggleTheme, setToggleTheme] = useState(false); //true stands for lite theme;
  const [themeIcon, setThemeIcon] = useState("dark");

  const [secondLayerMenuToggleStatus, setSecondLayerMenuToggleStatus] =
    useState({});

  const element = useRoutes(routes);
  const sideBarRoutes = routes.filter((route) => {
    return route.visibility;
  });

  const updateToggleStatus = (id, status) => {
    setSubMenuToggleStatus((prevState) => ({
      ...prevState,
      [id]: !status,
    }));
  };

  const updateSecondLayerSubmenuToggleStatus = (id, status) => {
    console.log(id);
    console.log(status);
    console.log(secondLayerMenuToggleStatus);
    setSecondLayerMenuToggleStatus((prevState) => ({
      ...prevState,
      [id]: !status,
    }));
  };

  const logOutHandler = () => {
    dispatch(userLoggedOut());
    nevigate("/");
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("toggleTheme"));
    if (items === true || items === false) {
      // console.log("inside");
      setToggleTheme(items);
      items ? setThemeIcon("lite") : setThemeIcon("dark"); //to sync toggle icons with themeColor
    }
  }, []);
  const themeToggleHandler = () => {
    setToggleTheme(!toggleTheme);
    localStorage.setItem("toggleTheme", JSON.stringify(!toggleTheme));
  };
  return (
    <div>
      <div
        style={{ display: loginCheck ? "block" : "none" }}
        className={toggleTheme ? " dark" : " lite"}
      >
        <div
          key="1"
          className={"sidebar " + (sideBarToggle ? "open" : "close")}
        >
          <div className="logo-details">
            <i
              className="bx bxl-c-plus-plus"
              onClick={() => setSideBarToggle(!sideBarToggle)}
            ></i>
            <span
              className="logo_name"
              onClick={() => setSideBarToggle(!sideBarToggle)}
            >
              Sensometer
            </span>
          </div>
          <ul className="nav-links">
            {sideBarRoutes.map((item, key) => {
              return (
                <>
                  {!item.children ? (
                    <li>
                      <Link to={item.path}>
                        <a href="#" style={{}}>
                          {/* <i className="bx bx-grid-alt"></i> */}
                          <i>{item.icon}</i>
                          <span className="link_name">{item.title}</span>
                        </a>
                      </Link>
                      <ul className="sub-menu blank">
                        <li>
                          <Link to={item.path}>
                            <a className="link_name" href="#">
                              {item.title}
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li className={subMenuToggleStatus[key] ? "showMenu" : ""}>
                      <div className="iocn-link ">
                        <a href="#">
                          <i className="sidebar-icon">{item.icon}</i>
                          <span
                            className="link_name"
                            onClick={() =>
                              updateToggleStatus(
                                key,
                                subMenuToggleStatus[key]
                                  ? subMenuToggleStatus[key]
                                  : false
                              )
                            }
                          >
                            {item.title}
                          </span>
                        </a>
                        <i
                          className="bx bxs-chevron-down arrow"
                          onClick={() =>
                            updateToggleStatus(
                              key,
                              subMenuToggleStatus[key]
                                ? subMenuToggleStatus[key]
                                : false
                            )
                          }
                        ></i>
                      </div>
                      <ul className="sub-menu">
                        <li>
                          <Link to={item.path}>
                            <a className="link_name" href="#">
                              {item.title}
                            </a>
                          </Link>
                        </li>
                        {item.children.map((elem,secondLayerKey) => {
                          return (
                            <>
                              {!elem.children ? (
                                <li>
                                  <Link to={`${item.path}/${elem.path}`}>
                                    {/* <a href="#">{elem.title}</a> */}
                                    <a href="#">{elem.title}</a>
                                  </Link>
                                </li>
                              ) : (
                                <li
                                  className={
                                    secondLayerMenuToggleStatus[secondLayerKey]
                                      ? "secondLayer-showMenu"
                                      : ""
                                  }
                                >
                                  <div className="iocn-link second-layer-title">
                                    <a href="#">
                                      {/* <i className="sidebar-icon">
                                            {item.icon}
                                          </i> */}
                                      <span
                                        // className="link_name"
                                        onClick={() =>
                                          updateSecondLayerSubmenuToggleStatus(
                                            secondLayerKey,
                                            secondLayerMenuToggleStatus[
                                              secondLayerKey
                                            ]
                                              ? secondLayerMenuToggleStatus[
                                                  secondLayerKey
                                                ]
                                              : false
                                          )
                                        }
                                      >
                                        {elem.title}
                                      </span>
                                    </a>
                                    <i
                                      className="bx bxs-chevron-down second-layer-arrow"
                                      onClick={() =>
                                        updateSecondLayerSubmenuToggleStatus(
                                          secondLayerKey,
                                          secondLayerMenuToggleStatus[
                                            secondLayerKey
                                          ]
                                            ? secondLayerMenuToggleStatus[
                                                secondLayerKey
                                              ]
                                            : false
                                        )
                                      }
                                    ></i>
                                  </div>
                                  <ul className="sub-menu-second-layer">
                                    {/* <li>
                                      <Link to={item.path}>
                                        <a className="link_name" href="#">
                                          {elem.title}
                                        </a>
                                      </Link>
                                    </li> */}

                                    <>
                                      {elem?.children?.map(
                                        (secondLayerData) => {
                                          return (
                                            <li className="second-layer-li">
                                              <Link
                                                to={`${item.path}/${elem.path}/${secondLayerData.path}`}
                                              >
                                                {/* <a href="#">{elem.title}</a> */}
                                                <a href="#">
                                                  {secondLayerData.title}
                                                </a>
                                              </Link>
                                            </li>
                                          );
                                        }
                                      )}
                                    </>
                                  </ul>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </li>
                  )}
                </>
              );
            })}
            {/* <li>
            <a href="#">
              <i className="bx bx-grid-alt"></i>
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Dashboard
                </a>
              </li>
            </ul>
          </li> */}

            {/* <li className={subMenuToggle ? "showMenu" : ""}> */}
            {/* <li>
            <div className="iocn-link ">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Category</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                // onClick={() => setSubMenuToggle(!subMenuToggle)}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Category
                </a>
              </li>
              <li>
                <a href="#">HTML & CSS</a>
              </li>
              <li>
                <a href="#">JavaScript</a>
              </li>
              <li>
                <a href="#">PHP & MySQL</a>
              </li>
            </ul>
          </li> */}
          </ul>
        </div>
        <section className="home-section">
          <div className={"home-content sticky-top"}>
            {/* <div className="icons"> */}

            <i
              className="bx bx-menu"
              onClick={() => setSideBarToggle(!sideBarToggle)}
            ></i>

            <div className="nav-right-icons">
              <Moment
                interval={1000}
                className="theme-text "
                format="D MMM YYYY h:mm:ss a"
                withTitle
              >
                {}
              </Moment>
              {/* <i onClick={() => {}}>
                <div className="theme-text notification">
                  <IoMdNotificationsOutline size="25px" />
                </div>
              </i> */}
              {/* <DrawerComponent /> */}
              <Drawer />
              <i
                onClick={() => {
                  // setToggleTheme(!toggleTheme);
                  themeToggleHandler();
                }}
              >
                {themeIcon == "dark" ? (
                  <div onClick={() => setThemeIcon("lite")}>
                    <HiOutlineSun size="25px" />
                  </div>
                ) : (
                  <div onClick={() => setThemeIcon("dark")}>
                    <BsMoon size="20px" />
                  </div>
                )}
              </i>
              {/* <Form className="">
            <Form.Check
              type="switch"
              id="custom-switch"
              label=""
              onClick={() => {
                setToggleTheme(!toggleTheme);
              }}
              // onTouchStart={()=>{setToggleTheme(!toggleTheme)}}

              className={toggleTheme ? "white" : "black"}
            />
          </Form> */}
              <i className="user-icon">
                <BiUserCircle size="25px" />
                <div className="user-section">
                  <div className="user-info">
                    <div>
                      <AiOutlineUser />
                      {user?.name}
                    </div>
                    <button className="theme-text" onClick={logOutHandler}>
                      {" "}
                      <IoIosLogOut />
                      Log Out
                    </button>
                  </div>
                </div>
              </i>
            </div>
            {/* <input type="checkbox" class="toggle-theme" checked={toggleTheme} />
          <label for="toggle-theme">Toggle Theme</label> */}
          </div>

          <div className={"component-container"}>
            <ThemeContext.Provider value={toggleTheme ? "dark" : "lite"}>
              {element}
              {/* {routes} */}
            </ThemeContext.Provider>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Sidebar;
