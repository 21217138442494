import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { ThemeContext } from "../../context/ThemeContext";
import { Table, Pagination } from "rsuite";
import { useGetAllBuildingsQuery } from "../../features/building/buildingApi";
import { useGetAssignedSlaveListQuery } from "../../features/slave-assign/slaveAssignApi";
import { useGetDeviceListQuery } from "../../features/slave-assign/slaveAssignApi";
import { useUpdateAssignedSlaveMutation } from "../../features/slave-assign/slaveAssignApi";
import { useSlaveAssignMutation } from "../../features/slave-assign/slaveAssignApi";
import { useGetAllTanksListQuery } from "../../features/tank/tankApi";
import { useCreateTankMutation } from "../../features/tank/tankApi";
import { useUpdateTankMutation } from "../../features/tank/tankApi";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function Tank() {
  const theme = React.useContext(ThemeContext);
  const [loadingState, setLoadingState] = useState(true);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});

  const { data: assignedSlaveList, isLoading } = useGetAssignedSlaveListQuery();

  const { data: tankList, isLoading: tankListLoading } =
    useGetAllTanksListQuery();
  const [updateTank] = useUpdateTankMutation();
  const [createTank] = useCreateTankMutation();

  useEffect(() => {
    if (!tankListLoading) {
      setLoadingState(false);
    }
  }, [tankListLoading]);

  let defaultData = [];
  let data = [];
  if (!tankListLoading) {
    // console.log(tankList);
    if (tankList.success) {
      defaultData = [...tankList.data];
      defaultData = defaultData?.map((premesisInfo) => {
        const date = new Date(premesisInfo.updated_at);
        const formattedDate = date.toLocaleString();
        return {
          ...premesisInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }
  const handleClose = () => {
    setShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      // alert("Create Form");
      console.log(JSON.stringify(updateFormData));
      createTank(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      //   alert("Update Form");
      //   console.log(JSON.stringify(updateFormData));
      updateTank(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    let formData = defaultData.find((object) => object.id == id) || {};
    setUpdateFormData(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage("");
    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Create Tank
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Create Tank" : "Update Tank"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label for="premisesName">
                        Tank Name <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tank Name"
                        // value={name}
                        name="tank_name"
                        defaultValue={updateFormData?.tank_name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="premisesName">
                        Tank Shape <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Tank Shape"
                        // value={name}
                        name="tank_shape"
                        defaultValue={updateFormData?.tank_shape || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <label for="add1">Tank Depth</label>
                        <input
                          type="text"
                          className="form-control"
                          fullWidth
                          name="tank_depth_inch"
                          onChange={(e) => dataChangeHandler(e)}
                          placeholder="Tank Depth"
                          value={updateFormData?.tank_depth_inch || ""}
                        />
                      </div>
                      <div className="form-group">
                        <div className="col">
                          <label for="add2">Tank Height</label>
                          <input
                            type="text"
                            className="form-control"
                            fullWidth
                            name="tank_height"
                            onChange={(e) => dataChangeHandler(e)}
                            placeholder="Tank Height"
                            value={updateFormData?.tank_height || ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <label for="add1">Tank Width</label>
                        <input
                          type="text"
                          className="form-control"
                          fullWidth
                          name="tank_width_inch"
                          onChange={(e) => dataChangeHandler(e)}
                          placeholder="Tank Width"
                          value={updateFormData?.tank_width_inch || ""}
                        />
                      </div>
                      <div className="form-group">
                        <div className="col">
                          <label for="add2">Tank Slope</label>
                          <input
                            type="text"
                            className="form-control"
                            fullWidth
                            name="tank_slop"
                            onChange={(e) => dataChangeHandler(e)}
                            placeholder="Tank Slope"
                            value={updateFormData?.tank_slop || ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col">
                        <label for="add1">Water Capacity</label>
                        <input
                          type="text"
                          className="form-control"
                          fullWidth
                          name="tank_capacity_litre"
                          onChange={(e) => dataChangeHandler(e)}
                          placeholder="Water Capacity"
                          value={updateFormData?.tank_capacity_litre || ""}
                        />
                      </div>
                      <div className="form-group">
                        <div className="col">
                          <label for="add2">Water Filled</label>
                          <input
                            type="text"
                            className="form-control"
                            fullWidth
                            name="water_filled"
                            onChange={(e) => dataChangeHandler(e)}
                            placeholder="Water Filled"
                            value={updateFormData?.water_filled || ""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Fish Capacity</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fish Capacity"
                        // value={name}
                        name="fish_capacity"
                        defaultValue={updateFormData?.fish_capacity || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table
            height={500}
            data={data || []}
            // loading={defaultData.length > 0 ? false : true}
            loading={loadingState}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Tank Name</HeaderCell>
              <Cell dataKey="tank_name" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Shape</HeaderCell>
              <Cell dataKey="tank_shape" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Depth</HeaderCell>
              <Cell dataKey="tank_depth_inch" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Height</HeaderCell>
              <Cell dataKey="tank_height" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Width</HeaderCell>
              <Cell dataKey="tank_width_inch" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Slope</HeaderCell>
              <Cell dataKey="tank_slop" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Water Capacity
              </HeaderCell>
              <Cell dataKey="tank_capacity_litre" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Water Filled</HeaderCell>
              <Cell dataKey="water_filled" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Fish Capacity
              </HeaderCell>
              <Cell dataKey="fish_capacity" />
            </Column>
            <Column
              width={210}
              align="center"
              flexGrow={0}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tank;
