import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Main from "../components/global/Main";
import Login from "../components/auth/Login";
import useAuthCheck from "../hooks/useAuthCheck";
import PublicRoute from "../components/PublicRoute";

import { useSelector } from "react-redux";

function BaseLayout() {
  const authCheck = useAuthCheck();
  const loginCheck = useSelector((state) => state.auth);
  useEffect(() => {
    return () => {};
  }, []);
  // if (!loginCheck.user) {
  //   <Navigate to="/" />;
  // }

  return (
    <>
      <Main />
    </>
  );
}

export default BaseLayout;
