import React from "react";
import Chart from "react-google-charts";

const LineChartOptions = {
  hAxis: {
    title: "Time",
  },
  vAxis: {
    title: "Value",
  },
  series: {
    1: { curveType: "function" },
  },
};
// const LineData = [
//   ["x", "temp1", "temp2", "temp3", "temp4"],
//   [0, 0, 0, 0, 0],
//   [1, 10, 5, 9, 1],
//   [2, 23, 15, 5, 2],
//   [3, 17, 9, 6, 3],
//   [4, 18, 10, 9, 4],
//   [5, 9, 5, 20, 5],
//   [6, 11, 3, 30, 6],
//   [7, 27, 19, 15, 7],
// ];
function MultiLineChart({ newData }) {
  const label = [["x", "temp1"]];
  const LineData = label.concat(newData);

  return (
    <div className="container mt-5">
      {/* <h2>React Google Line Chart Example</h2> */}
      <Chart
        width={"1200px"}
        height={"500px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={LineData}
        options={LineChartOptions}
        rootProps={{ "data-testid": "2" }}
      />
    </div>
  );
}

export default MultiLineChart;
