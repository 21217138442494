import React, { useEffect } from "react";
import LineChart from "./lineChart/LineChart";
import BarChart from "./barChart/BarChart";
import "./style/details.css";
import { useVoltChartDataFetchMutation } from "../../../features/energy-meter/details/energyMeterDetailsApi";
import { useKilowattChartDataFetchMutation } from "../../../features/energy-meter/details/energyMeterDetailsApi";
import { useAmpereChartDataFetchMutation } from "../../../features/energy-meter/details/energyMeterDetailsApi";
import { useEnergyChartDataFetchMutation } from "../../../features/energy-meter/details/energyMeterDetailsApi";
import { useParams } from "react-router-dom";

function EnergyDetailsMain() {
  const { meterId } = useParams();

  const [voltChartDataFetch, { data, isLoading }] =
    useVoltChartDataFetchMutation();

  const [kilowattChartDataFetch, { data: kiloWattDataResponse }] =
    useKilowattChartDataFetchMutation();

  const [ampereChartDataFetch, { data: ampereDataResponse }] =
    useAmpereChartDataFetchMutation();

  const [energyChartDataFetch, { data: energyDataResponse }] =
    useEnergyChartDataFetchMutation();

  useEffect(() => {
    voltChartDataFetch({
      meter_id: meterId,
    });
    kilowattChartDataFetch({
      meter_id: meterId,
    });
    ampereChartDataFetch({
      meter_id: meterId,
    });
    energyChartDataFetch({
      meter_id: meterId,
    });
  }, []);

  let voltageData = [];
  let kiloWattData = [];
  let ampereData = [];
  let energyData = [];

  let voltageElement = "";
  let kiloWattElement = "";
  let ampereElement = "";
  let energyElement = "";

  if (energyDataResponse) {
    energyData = energyDataResponse?.Energy;
    console.log(energyData);
    energyData = energyData?.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();
      return { ...item, created_at: formattedDate };
    });

    energyElement = <BarChart chartData={energyData} />;
  }

  if (ampereDataResponse) {
    ampereData = ampereDataResponse?.Ampere;

    ampereData = ampereData.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();
      return { ...item, created_at: formattedDate };
    });

    ampereElement = <LineChart chartData={ampereData} />;
  }
  if (kiloWattDataResponse) {
    kiloWattData = kiloWattDataResponse?.Watt;

    kiloWattData = kiloWattData.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();
      return { ...item, created_at: formattedDate };
    });

    kiloWattElement = <LineChart chartData={kiloWattData} />;
  }

  if (data) {
    // console.log(data?.Voltage);
    voltageData = data?.Voltage;

    voltageData = voltageData.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();
      return { ...item, created_at: formattedDate };
    });

    voltageElement = <LineChart chartData={voltageData} />;
  }
  if (!kiloWattDataResponse) {
    kiloWattElement = <div>Loading...</div>;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 ">
          <div className="row" style={{ margin: "0px" }}>
            <div className="line-chart col-md-8">
              {" "}
              <h3>Power</h3>
              {kiloWattElement}
              {/* <LineChart /> */}
            </div>
            <div
              className="col-md-4 calculation-portion "
              style={{ margin: "0px" }}
            >
              <div className="col-md-12 ">
                <h3>Energy Consupmtion</h3>
                <table style={{ width: "100%" }} className="energy-table">
                  <tr>
                    <th></th>
                    <th>Day</th>
                    <th>Month</th>
                  </tr>
                  <tr>
                    <td>Bill</td>
                    <td>14.21 $</td>
                    <td>565.75 $</td>
                  </tr>
                  <tr>
                    <td>Income</td>
                    <td>0 $</td>
                    <td>0 $</td>
                  </tr>
                  <tr>
                    <td>Exported Energy</td>
                    <td>0 kWh</td>
                    <td>0 kWh</td>
                  </tr>
                  <tr>
                    <td>Grid Consumption</td>
                    <td>17.76 kWh</td>
                    <td>707.18 kWh</td>
                  </tr>
                  <tr>
                    <td>Peak</td>
                    <td>0 kWh</td>
                    <td>0 kWh</td>
                  </tr>
                  <tr>
                    <td>Off Peak</td>
                    <td>0 kWh</td>
                    <td>0 kWh</td>
                  </tr>
                  <tr>
                    <td>Standard</td>
                    <td>17.76 kWh</td>
                    <td>707.18 kWh</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="bar-chart">
            <h3>Grid Consumption</h3>
            {/* <BarChart /> */}
            {energyElement}
          </div>
        </div>
        <div className="col-md-12">
          <div className="bar-chart">
            <h3>Voltage</h3>
            {voltageElement}
            {/* <LineChart /> */}
          </div>
        </div>
        <div className="col-md-12">
          <div className="bar-chart">
            <h3>Current</h3>
            {/* <LineChart /> */}
            {ampereElement}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnergyDetailsMain;
