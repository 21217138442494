import { apiSlice } from "../../api/apiSlice";

export const dashboardDetailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    lineChartDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/room-line-chart",
        method: "POST",
        body: data,
      }),
      transformResponse: (response, meta, arg) => {
        // console.log(response )
        // return response;
        function groupBy(arr, property) {
          return arr.reduce((result, obj) => {
            const key = obj[property];
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(obj);
            return result;
          }, {});
        }
        const keyValueData = Object.entries(response); //returns [key,value] pair
        let finalResponse = {};
        for (let [key, value] of keyValueData) {
          const groupByLevelData = {
            [key]: groupBy(value, "slave_address"),
          };
          finalResponse = { ...finalResponse, ...groupByLevelData };
        }
        
        // console.log(finalResponse);
        return finalResponse;
       
      },
    }),
    roomInfo: builder.query({
      query: (id) => `/apiV2/rooms/${id}`,
    }),
    gaugeSensorDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/room-gauge",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useLineChartDataFetchMutation,
  useRoomInfoQuery,
  useGaugeSensorDataFetchMutation,
} = dashboardDetailsApi;
