import { apiSlice } from "../api/apiSlice";


export const roomMapApi = apiSlice.injectEndpoints({
  tagTypes: ["SensorMap"],
  endpoints: (builder) => ({
    sensometerListFetchByDeviceId: builder.mutation({
      query: (data) => ({
        url: "/apiV2/device-sensometer-list",
        method: "POST",
        body: data,
      }),
    }),
    sensorListFetchBySensometerId: builder.mutation({
      query: (data) => ({
        url: "/apiV2/sensometer-sensor-list",
        method: "POST",
        body: data,
      }),
    }),
    unassignedSlaveListBySensorId: builder.mutation({
      query: (data) => ({
        url: "/apiV2/slave-list",
        method: "POST",
        body: data,
      }),
    }),
    assignSlaveStore: builder.mutation({
      query: (data) => ({
        url: "/apiV2/sensor-rooms-map-store",
        method: "POST",
        body: data,
      }),
    }),
    roomSensorMapList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/all-sensor-rooms-map",
        method: "POST",
        body: data,
      }),
      providesTags: ["SensorMap"],
    }),
    deleteSensorMap: builder.mutation({
      query: ({id}) => ({
        url: `/apiV2/sensor-rooms-map-destroy/${id}`,
        method: "DELETE",
        // body: data,
      }),
      invalidatesTags: ["SensorMap"],
    }),
  }),
});

export const {
  useSensometerListFetchByDeviceIdMutation,
  useSensorListFetchBySensometerIdMutation,
  useUnassignedSlaveListBySensorIdMutation,
  useAssignSlaveStoreMutation,
  useRoomSensorMapListMutation,
  useDeleteSensorMapMutation,
} = roomMapApi;
