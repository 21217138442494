import { apiSlice } from "../api/apiSlice";

export const fishDailyFeedApi = apiSlice.injectEndpoints({
  tagTypes: ["fish-daily-feed"],
  endpoints: (builder) => ({
    getDailyFishFeedList: builder.query({
      query: () => "/apiV2/daily-feed-list",
      providesTags: ["fish-daily-feed"],
    }),
    createDailyFishFeed: builder.mutation({
      query: (data) => ({
        url: "/apiV2/daily-feed-entry",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-daily-feed"],
    }),
    updateDailyFishFeed: builder.mutation({
      query: (data) => ({
        url: "/apiV2/daily-feed-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-daily-feed"],
    }),
  }),
});

export const {
  useGetDailyFishFeedListQuery,
  useCreateDailyFishFeedMutation,
  useUpdateDailyFishFeedMutation,
} = fishDailyFeedApi;
