import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { ThemeContext } from "../../context/ThemeContext";
import { Table, Pagination } from "rsuite";

import { useGetAllPremisesQuery } from "../../features/premises/premisesApi";

import { useGetAllBuildingsQuery } from "../../features/building/buildingApi";

import { useUpdateBuildingMutation } from "../../features/building/buildingApi";
import { useAddBuildingInfoMutation } from "../../features/building/buildingApi";

import { useGetAssignedSlaveListQuery } from "../../features/slave-assign/slaveAssignApi";
import { useGetDeviceListQuery } from "../../features/slave-assign/slaveAssignApi";
import { useUpdateAssignedSlaveMutation } from "../../features/slave-assign/slaveAssignApi";
import { useSlaveAssignMutation } from "../../features/slave-assign/slaveAssignApi";

import getBase64 from "../../helper/helper";
// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function SlaveAssign() {
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [premisesList, setPremisesList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [sensorList, setSensorList] = useState([]);

  //   const { data: allPremisesData, isLoading, error } = useGetAllPremisesQuery();
  const { data: allBuildingList, isLoading: BuildingListLoading } =
    useGetAllBuildingsQuery();

  const { data: assignedSlaveList, isLoading } = useGetAssignedSlaveListQuery();

  const { data: getDeviceList, isLoading: getDeviceListLoading } =
    useGetDeviceListQuery();

  const [updateBuilding] = useUpdateBuildingMutation();
  const [addBuildingInfo] = useAddBuildingInfoMutation();

  const [updateAssignedSlave] = useUpdateAssignedSlaveMutation();
  const [slaveAssign] = useSlaveAssignMutation();

  useEffect(() => {
    if (!getDeviceListLoading) {
      console.log(getDeviceList);
      setDeviceList(getDeviceList?.device);
      setSensorList(getDeviceList?.sensor);
    }
  }, [getDeviceListLoading]);

  let defaultData = [];
  let data = [];
  if (!isLoading) {
    // console.log(allPremisesData);
    if (assignedSlaveList.success) {
      defaultData = [...assignedSlaveList.data];
      defaultData = defaultData?.map((premesisInfo) => {
        const data = new Date(premesisInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...premesisInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);

  // const handleChange = (e) => {
  //   alert(e.target.name);

  //   setFile(e.target.files[0]);
  //   if (file) {
  //     setImageUrl(URL.createObjectURL(file));
  //   } else {
  //     setImageUrl(null);
  //   }
  // };

  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      // alert("Create Form");
      console.log(JSON.stringify(updateFormData));
      slaveAssign(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      //   alert("Update Form");
      //   console.log(JSON.stringify(updateFormData));
      updateAssignedSlave(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};

    delete formData.map_image; // as updating empty value keeps previous image on db
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    if (e.target.name === "map_image") {
      getBase64(e.target.files[0])
        .then((base64image) => {
          setUpdateFormData((previousValue) => ({
            ...previousValue,
            [e.target.name]: base64image,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        [e.target.name]: e.target.value,
      }));
    }
    // console.log(updateFormData);
  };

  const imageShowHandler = (area_map) => {
    console.log(area_map);
  };
  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Assign Slave
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              Assign Slave
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Device
                      </label>
                      <select
                        required
                        name="device_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.device_id || ""}
                      >
                        <option label="Choose one"></option>
                        {deviceList?.map((deviceInfo) => {
                          return (
                            <option value={deviceInfo?.id}>
                              {deviceInfo?.device_code}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="premises no">
                        Select Sensor
                      </label>
                      <select
                        required
                        name="sensor_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.sensor_id || ""}
                      >
                        <option label="Choose one"></option>
                        {sensorList?.map((sensorInfo) => {
                          return (
                            <option value={sensorInfo?.sensor_id}>
                              {sensorInfo?.sensor_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="premisesName">Sensometer Id </label>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Sensometer Id"
                        // value={name}
                        name="senso_meter_id"
                        defaultValue={updateFormData?.senso_meter_id || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="premisesName">Slave Number </label>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        placeholder="Slave Number"
                        // value={name}
                        name="slave_number"
                        defaultValue={updateFormData?.slave_number || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table
            height={500}
            data={data || []}
            loading={defaultData.length > 0 ? false : true}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={50}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Device Id </HeaderCell>
              <Cell dataKey="device_code" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Sensometer Id
              </HeaderCell>
              <Cell dataKey="senso_meter_id" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Sensor Name</HeaderCell>
              <Cell dataKey="sensor_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Slave Number</HeaderCell>
              <Cell dataKey="slave_number" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlaveAssign;
