import { apiSlice } from "../api/apiSlice";

export const generatorDashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGeneratorDashboardData: builder.query({
      query: () => "/apiV2/generator/dashboard",
    }),
  }),
});

export const { useGetGeneratorDashboardDataQuery,useLazyGetGeneratorDashboardDataQuery } = generatorDashboardApi;
