import { apiSlice } from "../api/apiSlice";

export const sensorApi = apiSlice.injectEndpoints({
  tagTypes: ["SensorList"],
  endpoints: (builder) => ({
    getAllSensorList: builder.query({
      query: () => "/apiV2/all-sensors",
      providesTags: ["SensorList"],
    }),
    insertSensor: builder.mutation({
      query: (data) => ({
        url: "/apiV2/sensors-store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SensorList"],
    }),
    updateSensorData: builder.mutation({
      query: (data) => ({
        url: "/apiV2/sensors",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SensorList"],
    }),
  }),
});

export const {
  useGetAllSensorListQuery,
  useInsertSensorMutation,
  useUpdateSensorDataMutation,
} = sensorApi;
