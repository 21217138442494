import React from 'react'

function CompC() {
  return (
    <div className="page">



		<footer className="footer">
			<div className="container">
				<div className="row align-items-center flex-row-reverse">
					<div className="col-md-12 col-sm-12 text-center">
						Copyright © 2022 <a href="#">Noa</a>. Designed with <span
							className="fa fa-heart text-danger"></span> by <a href="#"> Spruko </a> All rights reserved
					</div>
				</div>
			</div>
		</footer>
	
	</div>
  )
}

export default CompC