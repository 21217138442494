import { apiSlice } from "../api/apiSlice";

export const fishReportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    dailyFishReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-log-daily-report",
        method: "POST",
        body: data,
      }),
    }),
    monthlyFishReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-log-monthly-report",
        method: "POST",
        body: data,
      }),
    }),
    grossFishReport: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-log-report",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useDailyFishReportMutation,
  useMonthlyFishReportMutation,
  useGrossFishReportMutation,
} = fishReportApi;
