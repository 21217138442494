export default function timeAgo(timestamp) {
  const now = Date.now();
  const difference = now - timestamp;

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;

  if (difference < minute) {
    return "just now";
  } else if (difference < hour) {
    const minutes = Math.floor(difference / minute);
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (difference < day) {
    const hours = Math.floor(difference / hour);
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (difference < week) {
    const days = Math.floor(difference / day);
    return `${days} day${days === 1 ? "" : "s"} ago`;
  } else {
    return new Date(timestamp).toDateString();
  }
}
