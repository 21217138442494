import { apiSlice } from "../api/apiSlice";

export const buildingApi = apiSlice.injectEndpoints({
  tagTypes: ["building"],
  endpoints: (builder) => ({
    getAllBuildings: builder.query({
      query: () => "/apiV2/all-buildings",
      providesTags: ["building"],
    }),
    addBuildingInfo: builder.mutation({
      query: (data) => ({
        url: "/apiV2/buildings-store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["building"],
    }),
    updateBuilding: builder.mutation({
      query: (data) => ({
        url: "/apiV2/buildings",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["building"],
    }),
  }),
});

export const { useGetAllBuildingsQuery, useAddBuildingInfoMutation,
useUpdateBuildingMutation } = buildingApi;
