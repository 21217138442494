import React, { createContext } from "react";
import { useState, useEffect } from "react";
// import "./styles/main.css";
import { routes } from "../../routes/appRoutes";
import { useRoutes, Link, useNavigate, Navigate } from "react-router-dom";
import { IconContext } from "react-icons";
import Form from "react-bootstrap/Form";
import { ThemeContext } from "../../context/ThemeContext";
import { BiUserCircle } from "react-icons/bi";
import { HiOutlineSun } from "react-icons/hi";
import { BsMoon } from "react-icons/bs";
import Moment from "react-moment";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
import { userLoggedOut } from "../../features/auth/authSlice";
import ReactLoading from "react-loading";
import Sidebar from "./Sidebar";
import useAuth from "../../hooks/useAuth";

function Main() {
  const logincheck = useAuth();
  const element = useRoutes(routes);
  return (
    // <IconContext.Provider value={{ color: "#03cafc", size: "40px" }}>
    <div>
      <Sidebar />

      {!logincheck ? <div>{element}</div> : <div></div>}
    </div>
    // </IconContext.Provider>
  );
}

export default Main;
