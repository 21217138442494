import { apiSlice } from "../api/apiSlice";

export const fishDeseaseApi = apiSlice.injectEndpoints({
  tagTypes: ["fish-desease"],
  endpoints: (builder) => ({
    getFishDeseaseList: builder.query({
      query: () => "/apiV2/fish-disease-list",
      providesTags: ["fish-desease"],
    }),
    createFishDesease: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-disease-create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-desease"],
    }),
    updateFishDesease: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-disease-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-desease"],
    }),
  }),
});

export const {
  useGetFishDeseaseListQuery,
  useCreateFishDeseaseMutation,
  useUpdateFishDeseaseMutation,
} = fishDeseaseApi;
