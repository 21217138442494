import { apiSlice } from "../api/apiSlice";

export const motorControlApi = apiSlice.injectEndpoints({
  tagTypes: ["motor-control"],
  endpoints: (builder) => ({
    motorOnOff: builder.mutation({
      query: (data) => ({
        url: "/apiV2/switch",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["motor-control"],
    }),
    motorAutoManualSet: builder.mutation({
      query: (data) => ({
        url: "/apiV2/swicth-io",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["motor-control"],
    }),
    getMotorStatus: builder.query({
      query: () => "/apiV2/motor-status",
      providesTags: ["motor-control"],
    }),
  }),
});

export const {
  useMotorOnOffMutation,
  useGetMotorStatusQuery,
  useMotorAutoManualSetMutation,
} = motorControlApi;
