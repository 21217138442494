import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

import "./style/dashboard.css";
import ReactLoading from "react-loading";
import timeAgo from "../../helper/timeAgo";
import mqtt from "mqtt/dist/mqtt";
import negativeValueCalculate from "../../helper/negativeValueCalculate";
import { Link, useNavigate } from "react-router-dom";
import { generatePath } from "react-router-dom";
import thresholdStatusCalculate from "../../helper/thresholdStatusCalculate";
import { useGetEnergyDashboardDataQuery } from "../../features/energy-meter/energyMeterApi";
import { useLazyGetEnergyDashboardDataQuery } from "../../features/energy-meter/energyMeterApi";

function EnergyDashboard() {
  // let { data, isLoading } = useGetDashboardDataQuery();
  const theme = useContext(ThemeContext);
  // const [getDashboardData, results] = useLazyGetDashboardDataQuery();
  const navigate = useNavigate();
  // console.log(results.data);

  let { data, isLoading } = useGetEnergyDashboardDataQuery();
  const [getEnergyDashboardData, results] =
    useLazyGetEnergyDashboardDataQuery();

  // //mqtt code starts
  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8081,
  };

  let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/dashboard/data");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      getEnergyDashboardData();
    });
  }, [client]);

  const clickHandler = (id) => {
    // console.log(id);
    const path = generatePath("/energy_meter_dashboard/:meterId", {
      meterId: id,
    });
    navigate(path);
  };
  // // mqtt code ends

  // const roomClickHandler = (room_name) => {
  //   const path = generatePath("/dashboard/:roomId", { roomId: room_name });
  //   navigate(path);
  // };

  let energyDashboardData = "";
  let element = "";
  const types = {
    Voltage: "V",
    Ampere: "I",
    Watt: "P",
    Energy: "E",
  };

  if (isLoading) {
    element = (
      <div className="container loading-center">
        <ReactLoading type="spin" color="#6d6dae" height={100} width={50} />
      </div>
    );
  }
  if (!isLoading) {
    if (results?.data) {
      data = results?.data || {};
    }

    console.log(data);

    // console.log((data && data) || {});
    energyDashboardData = data && Object.entries(data);
    // console.log(energyDashboardData);
    element = (
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          {energyDashboardData ? (
            energyDashboardData.map(([meter, value]) => {
              const [meterId, phase] = meter?.split("|");

              return (
                <div class="col-md-4" onClick={() => clickHandler(meterId)}>
                  <div
                    className="room-card "
                    // onClick={() => roomClickHandler(roomId)}
                  >
                    <h4 className="room-name">{phase} Phase System</h4>
                    <div className="">
                      <div className="theme-text">
                        <div>
                          <h5>Meter-Id : {meterId}</h5>
                          <table style={{ width: "100%" }}>
                            {phase == 3 ? (
                              <tr>
                                <th className="icon-row">
                                  {/* <div className="voltage-icon">V</div> */}
                                </th>
                                <th>P1</th>
                                <th>P2</th>
                                <th>P3</th>
                              </tr>
                            ) : (
                              <tr>
                                <th className="icon-row">
                                  {/* <div className="voltage-icon">V</div> */}
                                </th>
                                <th>P1</th>
                              </tr>
                            )}

                            {Object.entries(value).map(([type, values]) => {
                              // console.log(JSON.stringify(value));

                              return (
                                <tr>
                                  <td className="icon-row">
                                    <div className="voltage-icon">
                                      {types[type]}
                                    </div>
                                  </td>
                                  {values?.map((item) => {
                                    return (
                                      <td>
                                        {item.value} {item.unit}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="theme-text">No Data Available</div>
          )}
        </div>
      </div>
    );
  }

  return <>{element}</>;
}

export default EnergyDashboard;
