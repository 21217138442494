import { apiSlice } from "../api/apiSlice";

export const tankApi = apiSlice.injectEndpoints({
  tagTypes: ["tank"],
  endpoints: (builder) => ({
    getAllTanksList: builder.query({
      query: () => "/apiV2/tanks",
      providesTags: ["tank"],
    }),
    createTank: builder.mutation({
      query: (data) => ({
        url: "/apiV2/tank-create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tank"],
    }),
    updateTank: builder.mutation({
      query: (data) => ({
        url: "/apiV2/tanks",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["tank"],
    }),
  }),
});

export const {
  useGetAllTanksListQuery,
  useCreateTankMutation,
  useUpdateTankMutation,
} = tankApi;
