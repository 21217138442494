import React from "react";
import Generator from "./assets/generator.png";
import { BsStopwatch } from "react-icons/bs";
import { SlSpeedometer } from "react-icons/sl";
import { CiTempHigh } from "react-icons/ci";
import { BiGasPump } from "react-icons/bi";
import { AiOutlineThunderbolt } from "react-icons/ai";
import timeAgo from "../../../helper/timeAgo";

function DashboardCard({ data }) {
  let time_ago = "";
  let latestTimeStamp = new Date(data?.updated_at);
  time_ago = timeAgo(latestTimeStamp);

  return (
    <div>
      <div className="card ">
        <div className="card-header">{data?.name}({time_ago})</div>
        <div className="card-body  theme-background zero-padding">
          {/* <h5 className="card-title theme-text">Light card title</h5>
          <p className="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p> */}

          <div className="top-portion">
            <div className="generator">
              <img src={Generator} alt="" />
            </div>
            <div className="on-off-portion">
              <div className="on-off-switch">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={data?.generator_status != 0 ? true : false}
                  />
                  <div class="slider">
                    <span>On</span>
                    <span>Off</span>
                  </div>
                </label>
              </div>
              <div className="runtime-section">
                <div className="icon-section">
                  <BsStopwatch size={25} className="icon" />
                </div>
                <div className="runtime-text ">Runtime</div>
                <div className="runtime-value"> {data?.runtime}</div>
              </div>
            </div>
          </div>
          <div className="bottom-portion">
            <div className="bottom-top-portion item-section">
              <div className="runtime-section">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <SlSpeedometer size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">RPM</div>
                </div>
                <div className="runtime-value"> {data?.engine_rpm}</div>
              </div>
              <div className="runtime-section">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <CiTempHigh size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">Temp</div>
                </div>
                <div className="runtime-value"> {data?.temperature}</div>
              </div>
            </div>
            <div className="bottom-bottom-portion item-section">
              <div className="runtime-section  ">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <BiGasPump size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">Oil Level</div>
                </div>
                <div className="runtime-value"> {data?.oil_level}%</div>
              </div>
              <div className="runtime-section">
                <div className="icon-title-section">
                  <div className="icon-section">
                    <AiOutlineThunderbolt size={25} className="icon" />
                  </div>
                  <div className="runtime-text theme-text">Energy</div>
                </div>
                <div className="runtime-value"> {data.total_watts}kWh</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard;
