import { apiSlice } from "../api/apiSlice";

export const roomsApi = apiSlice.injectEndpoints({
  tagTypes: ["rooms"],
  endpoints: (builder) => ({
    getAllRooms: builder.query({
      query: () => "/apiV2/all-rooms",

      providesTags: ["rooms"],
    }),
    roomStore: builder.mutation({
      query: (data) => ({
        url: "/apiV2/rooms-store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rooms"],
    }),
    updateRoomInfo: builder.mutation({
      query: (data) => ({
        url: "/apiV2/rooms",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["rooms"],
    }),
  }),
});

export const {
  useGetAllRoomsQuery,
  useRoomStoreMutation,
  useUpdateRoomInfoMutation,
} = roomsApi;
