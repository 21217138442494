import logo from "./logo.svg";
import "./App.css";
import { Link, useRoutes } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import CompB from "./components/CompB";
import CompC from "./components/CompC";
import { routes } from "./routes/appRoutes";


function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
