import { apiSlice } from "../api/apiSlice";

export const alertSettingsApi = apiSlice.injectEndpoints({
  tagTypes: ["Alert"],
  endpoints: (builder) => ({
    getAllAlertSettings: builder.query({
      query: () => "/apiV2/alert-list",
      providesTags: ["Alert"],
    }),
    alertSettingsUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/alert-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Alert"],
    }),
    alertSettingsSort: builder.mutation({
      query: (data) => ({
        url: "/apiV2/search",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Alert"],
    }),
  }),
});

export const {
  useGetAllAlertSettingsQuery,
  useAlertSettingsUpdateMutation,
  useAlertSettingsSortMutation,
} = alertSettingsApi;
