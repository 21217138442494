import { apiSlice } from "../api/apiSlice";

export const energyMeterApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEnergyDashboardData: builder.query({
      query: () => "/apiV2/energy-meter-dashboard",
      transformResponse: (response, meta, arg) => {
        function groupBy(arr, property) {
          return arr.reduce((result, obj) => {
            const key = obj[property];
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(obj);
            return result;
          }, {});
        }
        const keyValueData = Object.entries(response); //returns [key,value] pair
        let finalResponse = {};
        for (let [key, value] of keyValueData) {
          const groupByTypeData = {
            [key]: groupBy(value, "type"),
          };
          finalResponse = { ...finalResponse, ...groupByTypeData };
        }

        return finalResponse;
      },
    }),
  }),
});

export const { useGetEnergyDashboardDataQuery,useLazyGetEnergyDashboardDataQuery } = energyMeterApi;
