import { apiSlice } from "../../api/apiSlice";

export const energyMeterDetailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    voltChartDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-voltage-chart",
        method: "POST",
        body: data,
      }),
    }),
    kilowattChartDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-kilowatt-chart",
        method: "POST",
        body: data,
      }),
    }),
    ampereChartDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-ampere-chart",
        method: "POST",
        body: data,
      }),
    }),
    energyChartDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter-energy-chart",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useVoltChartDataFetchMutation,
  useKilowattChartDataFetchMutation,
  useAmpereChartDataFetchMutation,
  useEnergyChartDataFetchMutation,
} = energyMeterDetailsApi;
