import { apiSlice } from "../api/apiSlice";

export const fishFeedApi = apiSlice.injectEndpoints({
  tagTypes: ["fish-feed"],
  endpoints: (builder) => ({
    getFishFeedList: builder.query({
      query: () => "/apiV2/feed-list",
      providesTags: ["fish-feed"],
    }),
    createFishFeed: builder.mutation({
      query: (data) => ({
        url: "/apiV2/feed-create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-feed"],
    }),
    updateFishFeed: builder.mutation({
      query: (data) => ({
        url: "/apiV2/feed-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-feed"],
    }),
  }),
});

export const {
  useGetFishFeedListQuery,
  useCreateFishFeedMutation,
  useUpdateFishFeedMutation,
} = fishFeedApi;
