import { apiSlice } from "../api/apiSlice";

export const fishApi = apiSlice.injectEndpoints({
  tagTypes: ["fish"],
  endpoints: (builder) => ({
    getAllFishInfo: builder.query({
      query: () => "/apiV2/fish",
      providesTags: ["fish"],
    }),
    createFish: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish"],
    }),
    updateFish: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish"],
    }),
  }),
});

export const {
  useGetAllFishInfoQuery,
  useCreateFishMutation,
  useUpdateFishMutation,
} = fishApi;
