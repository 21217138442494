import { apiSlice } from "../../api/apiSlice";

export const filterApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    totalRoomFetch: builder.query({
      query: () => "/apiV2/rooms-filter",
    }),
    totalSensorListFetch: builder.query({
      query: () => "/apiV2/sensor-filter",
    }),
  }),
});

export const { useTotalRoomFetchQuery, useTotalSensorListFetchQuery } =
  filterApi;
