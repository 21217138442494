import { apiSlice } from "../api/apiSlice";

export const slaveAssignApi = apiSlice.injectEndpoints({
  tagTypes: ["slave"],
  endpoints: (builder) => ({
    getAssignedSlaveList: builder.query({
      query: () => "/apiV2/all-devices",
      providesTags: ["slave"],
    }),
    slaveAssign: builder.mutation({
      query: (data) => ({
        url: "/apiV2/devices-store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["slave"],
    }),
    updateAssignedSlave: builder.mutation({
      query: (data) => ({
        url: "/apiV2/devices",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["slave"],
    }),
    getDeviceList: builder.query({
      query: () => "/apiV2/device-sensor-slave-list",
    }),
  }),
});

export const { useGetAssignedSlaveListQuery, useGetDeviceListQuery,
useUpdateAssignedSlaveMutation,useSlaveAssignMutation } =
  slaveAssignApi;
