import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

function CompD() {
  const theme = useContext(ThemeContext)
  console.log(theme);
  return (
    <div>
      <div className={`row row-sm ${theme} `}>
        <div
          className="col-lg-6 col-xl-6 col-md-12 col-sm-12"
          style={{ height: "100vh !important" }}
        >
          <div className="card box-shadow-0 ">
            <div className="card-header border-bottom theme-background theme-text">
              <h3 className="card-title ">Default Form</h3>
            </div>
            <div className="card-body theme-background">
              <p className="text-muted theme-normal-text">
                It is Very Easy to Customize and it uses in your website
                apllication.
              </p>
              <form className="form-horizontal ">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    id="inputName"
                    placeholder="Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail2"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    placeholder="Password"
                  />
                </div>
                <div className="form-group">
                  <div className="checkbox">
                    <div className="custom-checkbox custom-control">
                      <input
                        type="checkbox"
                        data-checkboxes="mygroup"
                        className="custom-control-input"
                        id="checkbox-2"
                      />
                      <label for="checkbox-2" className="custom-control-label theme-normal-text">
                        Check me Out
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <div >
                    <button type="submit" className="btn btn-primary">
                      Sign in
                    </button>
                    <button type="submit" className="btn btn-secondary">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
          <div className="card box-shadow-0">
            <div className="card-header border-bottom">
              <h4 className="card-title">Vertical Form</h4>
            </div>
            <div className="card-body">
              <p className="text-muted">
                It is Very Easy to Customize and it uses in your website
                apllication.
              </p>
              <form>
                <div className="form-group">
                  <label for="exampleInputEmail2">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail2"
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword2">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword2"
                    placeholder="Password"
                  />
                </div>
                <div className="checkbox">
                  <div className="custom-checkbox custom-control">
                    <input
                      type="checkbox"
                      data-checkboxes="mygroup"
                      className="custom-control-input"
                      id="checkbox-1"
                    />
                    <label
                      for="checkbox-1"
                      className="custom-control-label mt-1"
                    >
                      Check me Out
                    </label>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompD;
