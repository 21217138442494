import { apiSlice } from "../api/apiSlice";

export const fishAssignApi = apiSlice.injectEndpoints({
  tagTypes: ["fish-assign"],
  endpoints: (builder) => ({
    getFishTankList: builder.query({
      query: () => "/apiV2/fish-tank-list",
      providesTags: ["fish-assign"],
    }),
    fishTankAssign: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-tank-assign",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-assign"],
    }),
    fishTankUnAssign: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-tank-unassigned",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-assign"],
    }),
  }),
});

export const {
  useGetFishTankListQuery,
  useFishTankAssignMutation,
  useFishTankUnAssignMutation,
} = fishAssignApi;
