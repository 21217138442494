import React, { useEffect, useState } from "react";
import "./styles/authStyles.css";
import { GrMail } from "react-icons/gr";
import { RiLockPasswordFill } from "react-icons/ri";
import { useLoginMutation } from "../../features/auth/authApi";
import { json, useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [login, { data, isLoading, error: responseError }] = useLoginMutation();
  const nevigate = useNavigate();
  useEffect(() => {
    if (responseError) {
      setError(responseError.status);
    }
    if (data?.data) {
      nevigate("/dashboard");
    }
  }, [data, responseError, nevigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    login({
      user_email: email,
      user_pass: password,
    });
  };
  if (isLoading) {
    console.log("loading.....");
  }
  if (!isLoading) {
    
    // nevigate("/compB/compD");
  
  }
  return (
    <div class="page card auth-body">
      <div>
        {/* <!-- CONTAINER OPEN --> */}
        <div class="col col-login mx-auto text-center">
          <a href="index.html" class="text-center">
            <img src="/sensometer.png" class="header-brand-img" alt="" />
          </a>
        </div>
        <div class="container-login100 ">
          <div class="wrap-login100 p-0">
            <div class="card-body">
              <form
                class="login100-form validate-form"
                onSubmit={(e) => handleSubmit(e)}
              >
                <span class="login100-form-title">Login</span>
                <div
                  class="wrap-input100 validate-input"
                  data-bs-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    class="input100"
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <span class="focus-input100"></span>
                  <span class="symbol-input100">
                    {/* <i class="zmdi zmdi-email" aria-hidden="true"></i> */}
                    <GrMail />
                  </span>
                </div>
                <div
                  class="wrap-input100 validate-input"
                  data-bs-validate="Password is required"
                >
                  <input
                    class="input100"
                    type="password"
                    name="pass"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span class="focus-input100"></span>
                  <span class="symbol-input100">
                    {/* <i class="zmdi zmdi-lock" aria-hidden="true"></i> */}
                    <RiLockPasswordFill />
                  </span>
                </div>
                {/* <div class="text-end pt-1">
                    <p class="mb-0">
                      <a href="forgot-password.html" class="text-primary ms-1">
                        Forgot Password?
                      </a>
                    </p>
                  </div> */}
                <div class="container-login100-form-btn">
                  <button type="submit" class="login100-form-btn btn-primary">
                    Login
                  </button>
                </div>
              </form>
              {error && <div style={{ color:'red' }}>{"email or password mismatch"}</div>}
            </div>
          </div>
        </div>
        {/* <!-- CONTAINER CLOSED --> */}
      </div>
    </div>
  );
}

export default Login;
