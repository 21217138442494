import { apiSlice } from "../api/apiSlice";

export const fishLogApi = apiSlice.injectEndpoints({
  tagTypes: ["fish-log"],
  endpoints: (builder) => ({
    getAllFishLog: builder.query({
      query: () => "/apiV2/fish-log",
      providesTags: ["fish-log"],
    }),
    createFishLog: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-log-create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-log"],
    }),
    updateFishLog: builder.mutation({
      query: (data) => ({
        url: "/apiV2/fish-log-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fish-log"],
    }),
  }),
});

export const {
  useGetAllFishLogQuery,
  useCreateFishLogMutation,
  useUpdateFishLogMutation,
} = fishLogApi;
