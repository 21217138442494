import { apiSlice } from "../api/apiSlice";
// import mqtt from "mqtt/dist/mqtt";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: () => "/apiV2/dashboard",
      transformResponse: (response, meta, arg) => {
        const keyValueData = Object.entries(response);
        let updatedTimeRoom = {};
        function getLatestDate(dateStr1, dateStr2) {
          const date1 = new Date(dateStr1);
          const date2 = new Date(dateStr2);

          if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
            throw new Error("Invalid date format");
          }

          return date1.getTime() > date2.getTime() ? dateStr1 : dateStr2;
        }

        for (let [key, value] of keyValueData) {
          // console.log(JSON.stringify(value));
          let date = "2000-07-10T07:55:42.000000Z";
          // console.log(date);
          for (let data of value) {
            date = getLatestDate(date, data.updated_at);
          }
          updatedTimeRoom[key] = date;
          // console.log(date);
        }
        

        function groupBy(arr, property) {
          return arr.reduce((result, obj) => {
            const key = obj[property];
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(obj);
            return result;
          }, {});
        }
        // const keyValueData = Object.entries(response); //returns [key,value] pair
        let finalResponse = {};
        for (let [key, value] of keyValueData) {
          const groupByLevelData = {
            [key]: groupBy(value, "level"),
          };
          finalResponse = { ...finalResponse, ...groupByLevelData };
        }
        // console.log(JSON.stringify(finalResponse));

        return { latestTime: updatedTimeRoom, data: finalResponse };
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        // console.log("onCacheEntryAdded");
        // let options = {
        //   clientId: Math.random(),
        //   username: "shahed",
        //   password: "Aqualink_321",
        //   // clean: true,
        //   reconnectPeriod: 1000,
        //   port: 8081,
        // };
        // let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
        // client.subscribe("/dashboard/data");
        // client.on("message", (topic, message) => {
        //   console.log(topic, message.toString());
        //   console.log("Inside mqtt listen");
        //   //  console.log(dashboardApi.getDashboardData);
        //   updateCachedData((draft) => {
        //     // draft.push({"murgirDim":52});
        //     // console.log(JSON.stringify(draft));
        //   });
        // });
      },
    }),
  }),
});

export const { useGetDashboardDataQuery, useLazyGetDashboardDataQuery } =
  dashboardApi;
