import React from "react";
import AllNotification from "./AllNotification";
import { useDataLogFetchMutation } from "../../features/dataLog/dataLogApi";
import { useEffect } from "react";
import { useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useContext } from "react";
import { useTotalRoomFetchQuery } from "../../features/dataLog/filters/filterApi";
import { useTotalSensorListFetchQuery } from "../../features/dataLog/filters/filterApi";
import negativeValueCalculate from "../../helper/negativeValueCalculate";

import { useAllNotificationFetchMutation } from "../../features/notification/notificationApi";

function NotificationMain() {
  const { data: roomList, isLoading: roomFetchQueryLoading } =
    useTotalRoomFetchQuery();

  const { data: sensorList, isLoading: sensorFetchQueryLoading } =
    useTotalSensorListFetchQuery();
  const theme = useContext(ThemeContext);
  const [dataLogFetch, { data: fetchedData, error: responseError }] =
    useDataLogFetchMutation();

  const [allNotificationFetch, { isLoading }] =
    useAllNotificationFetchMutation();
  

  const [notificationData, setNotificationData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [dataView, setDataView] = useState(10);
  const [date, setDate] = useState({
    startdate: null,
    enddate: null,
  });
  const [room_id, setRoom_id] = useState(null);
  const [sensor_id, setSensor_id] = useState(null);
  const [formData, setFormData] = useState({});

  let totalDataCount = 0;
  let tableData = [];
  let element = "";

  useEffect(() => {
    const initialObject = { dataview: dataView, page: activePage };
    allNotificationFetch({ ...initialObject, ...formData })
      .unwrap()
      .then((payload) => setNotificationData(payload))
      .catch((error) => {
        console.log(error);
      });
  }, [activePage, dataView]);

  const searchSumbitHandler = (e) => {
    e.preventDefault();

    console.log(formData);
    allNotificationFetch(formData)
      .unwrap()
      .then((payload) => setNotificationData(payload))
      .catch((error) => {
        console.log(error);
      });
    // if (date?.startdate && room_id && sensor_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     room_id: room_id,
    //     sensor_id: sensor_id,
    //   });
    // } else if (date?.startdate && room_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     room_id: room_id,
    //   });
    // } else if (date?.startdate && sensor_id) {
    //   dataLogFetch({
    //     dataview: dataView,
    //     page: 1,
    //     startdate: date?.startdate,
    //     enddate: date?.enddate,
    //     sensor_id: sensor_id,
    //   });
    // } else {
    //   dataLogFetch(date);
    // }

    // console.log(fetchedData);
  };

  const roomSelectHandler = (e) => {
    setRoom_id(e.target.value);
  };
  const sensorSelectHandler = (e) => {
    setSensor_id(e.target.value);
  };

  if (!isLoading) {
    // console.log("Data Loaded");
    // console.log(notificationData);
    const data = notificationData.data || [];

    tableData = data.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();

      return {
        ...item,
        created_at: formattedDate,
      };
    });

    totalDataCount = notificationData?.total || 0;

    // if (tableData.length > 0) {
    //   element = (
    //     <AllNotification
    //       tableData={tableData}
    //       setActivePage={setActivePage}
    //       setDataView={setDataView}
    //       activePage={activePage}
    //       totalDataCount={totalDataCount}
    //     />
    //   );
    // } else {
    //   element = <div>No Data Found</div>;
    // }
  }

  const formChangeHandler = (e) => {
    if (e.target.value == "") {
      delete formData[e.target.name];
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className={`${theme}`}>
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="form-group col-md-2">
                <label className="theme-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="startdate"
                  onChange={formChangeHandler}
                  //   required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="theme-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="enddate"
                  onChange={formChangeHandler}
                />
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="room_id"
                  // value={sensor_id}
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Chamber
                  </option>
                  {roomList?.map((room) => {
                    return (
                      <option key={room?.room_id} value={room?.room_id}>
                        {room?.room_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="sensor_id"
                  value={sensor_id}
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Sensor
                  </option>
                  {sensorList?.map((sensor) => {
                    return (
                      <option
                        key={sensor?.parameter_id}
                        value={sensor?.parameter_id}
                      >
                        {sensor?.sensor_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="type"
                  onChange={formChangeHandler}
                >
                  <option value="" selected>
                    Select Type
                  </option>
                  <option value="Success">Success</option>
                  <option value="Danger">Danger</option>
                  <option value="Warning">Warning</option>
                </select>
              </div>
              <div className="col-md-1 form-group ">
                <label for="sensorUnit">Slave No.</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Slave No."
                  name="slave_address"
                  onChange={formChangeHandler}
                  // value={searchFormData?.slave_number || ""}
                />
              </div>
              <div className="col-md-1 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="col-md-2 date-search-submit-btn">
          <button
            type="submit"
            class="btn btn-info col-md-12"
            onClick={(e) => downloadCSVHandler(e)}
          >
            {" "}
            Download CSV
          </button>
        </div> */}
      </div>
      {/* {element} */}
      <AllNotification
        tableData={tableData}
        setActivePage={setActivePage}
        setDataView={setDataView}
        activePage={activePage}
        totalDataCount={totalDataCount}
      />
    </div>
  );
}

export default NotificationMain;
