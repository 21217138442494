import { apiSlice } from "../api/apiSlice";

export const dataLogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    dataLogFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/datatables",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useDataLogFetchMutation } = dataLogApi;
