import { apiSlice } from "../api/apiSlice";

export const deviceConfigApi = apiSlice.injectEndpoints({
  tagTypes: ["deviceConfig"],
  endpoints: (builder) => ({
    getDeviceConfigList: builder.query({
      query: () => "/apiV2/devices-config-list",
      providesTags: ["deviceConfig"],
    }),
    updateDeviceConfigList: builder.mutation({
      query: (data) => ({
        url: "/apiV2/devices-config",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deviceConfig"],
    }),
  }),
});

export const {
  useGetDeviceConfigListQuery,
  useUpdateDeviceConfigListMutation,
} = deviceConfigApi;
