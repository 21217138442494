import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLoggedOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userLoggedOut());
    navigate("/");
  }, [dispatch]);

  return <div></div>;
}

export default Logout;
