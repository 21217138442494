import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { ThemeContext } from "../context/ThemeContext"; //this import may vary according to directory
import { Table, Pagination } from "rsuite";
const { Column, HeaderCell, Cell } = Table;

function BasicTableComponenet() {
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
    let defaultData = [];
  const handleClose = () => {
    setShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = () => {};

  const dataChangeHandler = (e) => {
    setUpdateFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          type="submit"
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
          }}
        >
          Add Sensor
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Add Premises" : "Update Premises"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label for="sensorName">Sensor Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Sensor Name"
                        // value={name}
                        defaultValue={updateFormData?.sensor_name || ""}
                        name="sensor_name"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label for="sensorUnit">Sensor Unit</label>
                      <input
                        type="text"
                        className="form-control"
                        name="unit"
                        placeholder="Sensor Unit"
                        defaultValue={updateFormData?.unit || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label for="sendorId">Sensor Id</label>
                      <input
                        type="text"
                        name="sensor_id"
                        defaultValue={updateFormData?.sensor_id || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        className="form-control"
                        placeholder="Sensor Id"
                        required
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table
            height={500}
            data={[]}
            loading={defaultData.length > 0 ? false : true}
          >
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>No.</HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column
              width={200}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      //   setCreateFormSelector(false);
                      //   handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicTableComponenet;
