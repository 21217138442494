import { apiSlice } from "../api/apiSlice";

export const premisesApi = apiSlice.injectEndpoints({
  tagTypes: ["premises"],
  endpoints: (builder) => ({
    getAllPremises: builder.query({
      query: () => "/apiV2/all-premises",
      providesTags: ["premises"],
    }),

    premisesStore: builder.mutation({
      query: (data) => ({
        url: "/apiV2/premises-store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premises"],
    }),
    updatePremisesInfo: builder.mutation({
      query: (data) => ({
        url: "/apiV2/premises",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["premises"],
    }),
    getAllBuildings: builder.query({
      query: () => "/apiV2/all-buildings",
      providesTags: ["building"],
    }),
    addBuilding: builder.mutation({
      query: (data) => ({
        url: "/apiV2/buildings-store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Buildings"],
    }),
  }),
});

export const {
  useGetAllPremisesQuery,
  useGetAllBuildingsQuery,
  useAddBuildingMutation,
  usePremisesStoreMutation,
  useUpdatePremisesInfoMutation,
} = premisesApi;
